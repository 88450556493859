import React, { useEffect, useState } from 'react'
import Card from '@components/Card'
import Dialog from '@components/Dialog'
import DeletePopover from '@components/DeletePopover'

import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'

import TableParticipants from './TableParticipants'
import { useTranslation } from 'react-i18next'

const ParticipantsCycleJourney = ({ data, setData, handleChange }) => {
  const { t } = useTranslation()

  const journey = data

  const [openTable, setOpenTable] = useState(false)
  const [participantsSelected, setParticipantsSelected] = useState()

  useEffect(() => {
    setParticipantsSelected(journey.cycleJourneysParticipants)
  }, [journey.cycleJourneysParticipants])

  const removeParticipantFromList = id => {
    const newParticipants = journey.cycleJourneysParticipants.filter(
      participant => participant.idParticipant !== id
    )

    setData({ ...data, cycleJourneysParticipants: newParticipants })
  }

  const handleCheckBox = (id, value) => {
    const newParticipants = journey.cycleJourneysParticipants.map(participant =>
      participant.idParticipant == id
        ? { ...participant, cycleJourneyAssistance: value }
        : participant
    )
    setData({ ...data, cycleJourneysParticipants: newParticipants })
  }

  const addParticipantsToJourney = () => {
    setData({ ...data, cycleJourneysParticipants: participantsSelected })
  }

  return (
    <Card>
      {openTable && (
        <Dialog
          disabled
          open={openTable ? true : false}
          width='50%'
          height='75%'
          hasCloseBtn={false}
          title={t('CYCLES.SEARCH_PARTCIPANTS')}
          close={() => setOpenTable(false)}
          content={
            <TableParticipants
              setParticipantsSelected={setParticipantsSelected}
              participantsSelected={participantsSelected}
              companiesList={data.cycleCompanies}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.OUT')}
              </Button>
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  addParticipantsToJourney()
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('CYCLES.SAVE_FORM_CHANGES')}
              </Button>
            </Box>
          }
        />
      )}

      <Button
        variant='contained'
        onClick={() => setOpenTable(true)}
        sx={{
          textTransform: 'none'
        }}
        endIcon={<VisibilityIcon />}
      >
        {t('CYCLES.JOURNEYS.SHOW_PARTICIPANTS_LIST')}
      </Button>

      <List>
        {journey?.cycleJourneysParticipants.map((participant, index) => {
          const fullName =
            participant.participantName + ' ' + participant.participantSurname

          return (
            <Box key={participant.idParticipant}>
              <ListItem
                sx={{
                  bgcolor: 'theme.palette.primary.main'
                }}
                secondaryAction={
                  <DeletePopover
                    remove={() => {
                      removeParticipantFromList(
                        participant.idParticipant,
                        index
                      )
                    }}
                  />
                }
              >
                <ListItemIcon>
                  <Tooltip title={t('TEXT.ATTENDANCE')}>
                    <Checkbox
                      value={participant.cycleJourneyAssistance || ''}
                      sx={{ p: 0 }}
                      onChange={e =>
                        handleCheckBox(
                          participant.idParticipant,
                          e.target.checked
                        )
                      }
                      checked={participant.cycleJourneyAssistance}
                    />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={
                    fullName ? (
                      fullName
                    ) : (
                      <Typography
                        component='span'
                        variant='body2'
                        color='text.primary'
                      >
                        {t('CYCLES.NO_USER_DATA')}
                      </Typography>
                    )
                  }
                  secondary={
                    <React.Fragment>
                      {participant.participantEmail ? (
                        <Typography
                          sx={{ display: 'inline' }}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {participant.participantEmail}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ display: 'inline' }}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {t('CYCLES.NO_EMAIL_DATA')}
                        </Typography>
                      )}
                      {participant.companyName && (
                        <Typography component='div' variant='body2' color='text.primary'>
                          {participant.companyName}
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
            </Box>
          )
        })}
      </List>
    </Card>
  )
}

export default ParticipantsCycleJourney
