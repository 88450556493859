import apiRequest from '@helpers/apiRequest'

const sendCallOrReminder = data => {
  return apiRequest('POST', `RabbitMq/SendCallOrReminder`, data)
}

const sendDirectMail = data => {
  return apiRequest('POST', `RabbitMq/sendDirectMail`, data)
}
const sendMailRRHH = data => {
  return apiRequest('POST', `RabbitMq/sendMailRRHH/${data.idCycle}`, data.to)
}
const getMailRRHH = data => {
  return apiRequest('POST', `RabbitMq/GetMailRRHH`, data)
}
const getCallOrReminder = data => {
  return apiRequest('POST', `RabbitMq/GetCallOrReminder`, data)
}
const GetInvitedEmail = data => {
    return apiRequest('POST', `RabbitMq/GetInvitedEmail`, data)
}
const serviceRabbitMq = {
  sendCallOrReminder,
  sendDirectMail,
  sendMailRRHH,
  getCallOrReminder,
  getMailRRHH,
  GetInvitedEmail
}

export default serviceRabbitMq
